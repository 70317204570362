<template>
  <router-view />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",

  data: () => ({
    //
  }),

  created() {
    let clientHeight = document.documentElement.clientHeight;

    this.setClientHeight(clientHeight);
  },

  methods: {
    ...mapActions("common", ["setClientHeight"])
  }
};
</script>
