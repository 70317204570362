export default {
  namespaced: true,
  state: {
    navMenu: null,
    clientHeight: "0"
  },
  getters: {
    getMenu(state) {
      return state.navMenu;
    },

    getClientHeight(state) {
      return state.clientHeight;
    }
  },
  mutations: {
    SET_CLIENT_HEIGHT(state, height) {
      state.clientHeight = `${height}px`;
    }
  },
  actions: {
    setClientHeight({ commit }, height) {
      commit("SET_CLIENT_HEIGHT", height);
    }
  }
};
