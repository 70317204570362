const routes = [
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/Login"),
    meta: {
      public: true,
      onlyWhenLoggedOut: true,
      title: "羽西视频管理"
    }
  },
  {
    name: "index",
    path: "/",
    redirect: { name: "dashboard" },
    component: () => import("@/views/Index"),
    children: [
      {
        name: "dashboard",
        path: "/dashboard",
        component: () => import("@/views/Dashboard"),
        meta: {
          public: false, // TODO: false
          label: "首页",
          icon: "mdi-home",
          show: true
        }
      },
      {
        name: "project",
        path: "/project",
        redirect: { name: "project-list" },
        component: () => import("@/views/Projects/Index"),
        meta: {
          public: false, // TODO: false
          label: "项目管理",
          icon: "mdi-clipboard-list",
          show: true
        },
        children: [
          {
            name: "single-project",
            path: "single",
            redirect: { name: "project-list" },
            component: () => import("@/views/Projects/Single/Index"),
            meta: {
              public: false, // TODO: false
              label: "单个项目",
              icon: "mdi-file-document",
              show: true
            },
            children: [
              {
                name: "project-list",
                path: "list",
                component: () => import("@/views/Projects/Single/List"),
                meta: {
                  public: false, // TODO false
                  label: "章节列表",
                  icon: "mdi-list",
                  show: false
                }
              },
              {
                name: "project-video",
                path: "video",
                component: () => import("@/views/Projects/Single/Video"),
                meta: {
                  public: false, // TODO false
                  label: "内容编辑",
                  icon: "mdi-video",
                  show: false
                }
              }
            ]
          }
        ]
      },
      {
        name: "statistics",
        path: "/statistics",
        redirect: { name: "qrcode-sta" },
        component: () => import("@/views/Statistics/Index"),
        meta: {
          public: false, // TODO: false
          label: "数据统计",
          icon: "mdi-database",
          show: true
        },
        children: [
          {
            name: "qrcode-sta",
            path: "qrcode",
            component: () => import("@/views/Statistics/QRcode"),
            meta: {
              public: false, // TODO: false
              label: "扫码量统计",
              icon: "mdi-qrcode",
              show: true
            }
          },
          {
            name: "thumbup-sta",
            path: "thumbup",
            component: () => import("@/views/Statistics/ThumbUp"),
            meta: {
              public: false, // TODO: false
              label: "点赞量统计",
              icon: "mdi-thumb-up",
              show: true
            }
          },
          {
            name: "favorite-sta",
            path: "favorite",
            component: () => import("@/views/Statistics/Favorite"),
            meta: {
              public: false, // TODO: false
              label: "收藏量统计",
              icon: "mdi-heart",
              show: true
            }
          },
          {
            name: "share-sta",
            path: "share",
            component: () => import("@/views/Statistics/Share"),
            meta: {
              public: false, // TODO: false
              label: "转发量统计",
              icon: "mdi-share-variant",
              show: true
            }
          }
        ]
      }
    ]
  }
];

export default routes;
