import { ApiService, AuthError } from "./api.service";
import TokenService from "./token.service";

const UserService = {
  login: async function(data) {
    const requestData = {
      method: "POST",
      url: "/auth/login",
      data
    };

    try {
      const response = await ApiService.request(requestData);

      TokenService.setToken(response.data.token);
      ApiService.setHeader();

      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.data.errMsg);
    }
  },

  logout() {
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();

    ApiService.unmount401Interceptor();
  }
};

export default UserService;
