import { AuthError } from "../../services/api.service";
import VideoService from "../../services/video.service";

export default {
  namespaced: true,
  state: {
    video: null,
    currentEditingVideo: null,
    errorCode: 200,
    errorMessage: ""
  },
  getters: {
    getVideo(state) {
      return state.video;
    },

    getError(state) {
      return { errorCode: state.errorCode, errorMessage: state.errorMessage };
    }
  },

  actions: {
    async fetchVideo({ commit }, id) {
      try {
        const result = await VideoService.fetchVideo(id);

        commit("FETCH_VIDEO", result);
        return result;
      } catch (error) {
        if (error instanceof AuthError) {
          commit("SET_ERROR", {
            errorCode: error.errorCode,
            errorMessage: error.message
          });
        }

        return false;
      }
    },

    async setVideo({ commit }, data) {
      try {
        const result = await VideoService.createVideo(data);

        commit("SET_VIDEO", result);
        return result;
      } catch (error) {
        if (error instanceof AuthError) {
          commit("SET_ERROR", {
            errorCode: error.errorCode,
            errorMessage: error.message
          });
        }

        return false;
      }
    }
  },

  mutations: {
    SET_ERROR(state, { errorCode, errorMessage }) {
      state.errorCode = errorCode;
      state.errorMessage = errorMessage;
    },

    FETCH_VIDEO(state, result) {
      state.video = result;
    },

    SET_VIDEO(state, result) {
      state.currentEditingVideo = result;
    }
  }
};
