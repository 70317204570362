import { ApiService, AuthError } from "./api.service";

const ChapterService = {
  /**
   * Get the list of the chapters
   *
   * @param params
   * @returns {Promise<*>}
   */
  list: async function(params) {
    const requestParams = {
      method: "GET",
      url: "/chapter/list",
      params
    };

    try {
      const response = await ApiService.request(requestParams);

      return response.data.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.data.errMsg);
    }
  },

  /**
   * Get videos of a chapter
   *
   * @param chapterId
   * @param params
   * @returns {Promise<*>}
   */
  videos: async function(chapterId, params) {
    const requestData = {
      method: "GET",
      url: `/chapter/${chapterId}/videos`,
      params
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  createChapter: async function({ title }) {
    const requestData = {
      method: "POST",
      url: "/chapter/create",
      data: { title }
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (e) {
      throw new AuthError(e.errorCode, e.message);
    }
  }
};

export default ChapterService;
