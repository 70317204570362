import Vue from "vue";
import VueRouter from "vue-router";
import routes from "@/router/routes";
import TokenService from "../services/token.service";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );
  const loggedIn = !!TokenService.getToken();

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (!isPublic && !loggedIn) {
    return next({
      path: "/login",
      query: { redirect: from.fullPath }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next({
      path: "/"
    });
  }

  next();
});

export default router;
