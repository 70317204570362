const TOKEN_KEY = "YUESAI_ADMIN_TOKEN";
const REFRESH_TOKEN_KEY = "YUESAI_ADMIN_REFRESH_TOKEN";

const TokenService = {
  /**
   * Set access token
   *
   * @param accessToken
   */
  setToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  /**
   * Get access token
   *
   * @returns {string}
   */
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  /**
   * Remove access token
   */
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  /**
   * Set refresh token
   *
   * @param refreshToken
   */
  setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  /**
   * Get refresh token
   *
   * @returns {string}
   */
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  /**
   * Remove refresh token
   */
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }
};

export default TokenService;
