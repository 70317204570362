import { ApiService, AuthError } from "./api.service";

const VideoService = {
  createVideo: async function(data) {
    const requestData = {
      method: "POST",
      url: "/video/create",
      data
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  fetchVideo: async function(id) {
    const requestData = {
      method: "GET",
      url: `/video/detail/${id}`
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  // uploadVideo: async function(data) {
  //   try {
  //     const response = await ApiService.upload("/video/upload", data);
  //
  //     return response.data;
  //   } catch (error) {
  //     throw new AuthError(error.errorCode, error.message);
  //   }
  // },

  generateQr: async function(data) {
    try {
      const requestData = {
        method: "POST",
        url: "/video/generate-qr",
        data
      };
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  saveQr: async function({ video_id, qrcode }) {
    try {
      const requestData = {
        method: "POST",
        url: `/video/upload-qr`,
        data: {
          video_id,
          qrcode
        }
      };
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  downloadQr: async function({ video_id }) {
    try {
      const requestData = {
        method: "POST",
        url: `/video/download-qr`,
        data: {
          video_id
        }
      };
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  getSignature: async function({ chapterId }) {
    try {
      const requestData = {
        method: "GET",
        url: "/video/oss-signature",
        params: { chapterId }
      };

      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  uploadToOss: async function(params) {
    const requestData = {
      method: "POST",
      url: params.host,
      data: params.data
    };
    ApiService.removeHeader();
    const response = await ApiService.request(requestData);

    return response;
  },

  updateVideo: async function(params) {
    const requestData = {
      method: "POST",
      url: "/video/update",
      params
    };

    try {
      ApiService.setHeader();
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  initVideoSettings: async function(videoId) {
    const requestData = {
      method: "POST",
      url: "/video/initsettings",
      data: { videoId }
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  deleteVideo: async function(vid) {
    const requestData = {
      method: "POST",
      url: `/video/delete/${vid}`,
      data: { videoId: vid }
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  },

  deleteVideoFile: async function(vid) {
    const requestData = {
      method: "POST",
      url: `/video/delete-file`,
      data: { videoId: vid }
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.errorCode, error.message);
    }
  }
};

export default VideoService;
