import UserService from "../../services/user.service";
import { AuthError } from "../../services/api.service";
import router from "../../router/index";

export default {
  namespaced: true,
  state: {
    token: "",
    authenticating: false,
    authError: "",
    authErrorCode: 200
  },
  getters: {},
  mutations: {
    LOGIN_REQUEST(state) {
      state.authenticating = true;
      state.authError = "";
      state.authErrorCode = 200;
    },

    LOGIN_SUCCESS(state, token) {
      state.token = token;
      state.authenticating = false;
    },

    LOGIN_FAIL(state, { errorCode, errMsg }) {
      this.authenticating = false;
      this.authError = errMsg;
      this.authErrorCode = errorCode;
    },

    LOGOUT_SUCCESS(state) {
      state.token = "";
      state.authenticating = false;
      state.authErrorCode = 200;
      state.authError = "";
    }
  },
  actions: {
    async login({ commit }, data) {
      commit("LOGIN_REQUEST");

      try {
        const result = await UserService.login(data);
        commit("LOGIN_SUCCESS", result.token);

        await router.push(router.history.current.query.redirect || "/");
      } catch (e) {
        if (e instanceof AuthError) {
          commit("LOGIN_FAIL", { errorCode: e.errorCode, errMsg: e.message });
        }

        return false;
      }
    },

    logout({ commit }) {
      UserService.logout();
      commit("LOGOUT_SUCCESS");
    }
  }
};
