import ChapterService from "../../services/chapter.service";
import { AuthError } from "../../services/api.service";

export default {
  namespaced: true,
  state: {
    list: [],
    videos: [],
    currentNewChapter: null,
    errorCode: 200,
    errorMessage: ""
  },
  getters: {
    getList(state) {
      return state.list;
    },

    getVideos(state) {
      return state.videos;
    },

    getError(state) {
      return { errorCode: state.errorCode, errorMessage: state.errorMessage };
    },

    getCurrentChapter(state) {
      return state.currentNewChapter;
    }
  },

  actions: {
    async setList({ commit }, params) {
      try {
        const result = await ChapterService.list(params);

        commit("SET_LIST", result);
      } catch (error) {
        if (error instanceof AuthError) {
          commit("SET_ERROR", {
            errorCode: error.errorCode,
            errorMessage: error.message
          });
        }

        return false;
      }
    },

    /**
     * Set videos of a chapter
     *
     * @param commit
     * @param id
     * @param params
     * @returns {Promise<void>}
     */
    async setChapterVideos({ commit }, { id, params }) {
      try {
        const result = await ChapterService.videos(id, params);

        commit("SET_VIDEOS", result);
      } catch (e) {
        if (e instanceof AuthError) {
          commit("SET_ERROR", {
            errorCode: e.errorCode,
            errorMessage: e.message
          });
        }
      }
    },

    async createChapter({ commit }, data) {
      try {
        const result = await ChapterService.createChapter(data);

        commit("SET_NEW_CHAPTER", result);
      } catch (e) {
        if (e instanceof AuthError) {
          commit("SET_ERROR", {
            errorCode: e.errorCode,
            errorMessage: e.message
          });
        }
      }
    }
  },

  mutations: {
    SET_ERROR(state, { errorCode, errorMessage }) {
      state.errorCode = errorCode;
      state.errorMessage = errorMessage;
    },

    SET_LIST(state, result) {
      state.list = result;
    },

    SET_VIDEOS(state, result) {
      state.videos = result;
    },

    SET_NEW_CHAPTER(state, result) {
      state.currentNewChapter = result;
    }
  }
};
