import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { ApiService } from "./services/api.service";
import TokenService from "./services/token.service";
import vuetify from "./plugins/vuetify";
import "./assets/style/main.scss";

Vue.config.productionTip = false;

ApiService.init();

if (TokenService.getToken()) {
  ApiService.setHeader();
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
